import React from 'react';
import { Navbar } from './Navbar';
import { Investition } from './Investition';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Zinstrafo } from './Zinstrafo';
import { Home } from './Home';
import MathJax from 'react-mathjax';
import { Plan } from './Plan';
import { ComingSoon } from './ComingSoon';
import { Impressum } from './Impressum';
import { Annu } from './Annu';
import '@fortawesome/fontawesome-free/css/all.min.css';

export class App extends React.Component {
    render() {
        return (
            <MathJax.Provider>
                <Router>
                    <div>
                        <Navbar/>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/cashflow" component={Investition}/>
                        <Route exact path="/zinsen" component={Zinstrafo}/>
                        <Route exact path="/capm" component={ComingSoon}/>
                        <Route exact path="/plan" component={Plan}/>
                        <Route exact path="/fisher" component={ComingSoon}/>
                        <Route exact path="/dean" component={ComingSoon}/>
                        <Route exact path="/indifferenzkurven" component={ComingSoon}/>
                        <Route exact path="/impress" component={Impressum}/>
                        <Route exact path="/optionen" component={ComingSoon}/>
                        <Route exact path="/annu" component={Annu}/>
                    </div>    
                </Router>
            </MathJax.Provider>
        );
    }
}