import React from 'react';
import PropTypes from 'prop-types';

export const CashflowInput = (props) => {
    return (
        <div className="inline-block">
            <input
                type="text"
                placeholder={'Zins: ' + props.zins}
                onChange={props.onZinsChange}
                className="w-24 border border-r-0 rounded-l py-2 px-3"
            />
            <button className="hover:bg-grey-lightest py-2 px-3 border border-r-0 border-grey-light" onClick={props.addCashFlowEntry}><i className="fas fa-plus"></i></button>
            <button className="hover:bg-grey-lightest py-2 px-3 border border-r-0 border-grey-light" onClick={props.removeCashflowEntry}><i className="fas fa-minus"></i></button>
            {props.data.map((i, k) => {
                return (
                    <input
                        key={k}
                        type="text"
                        placeholder={i}
                        onChange={(e) => props.onChange(e, k)}
                        className={'w-24 border py-2 px-3' + (k == props.data.length - 1 ? ' rounded-r' : ' border-r-0')}
                    />
                );
            })}
        </div>
    );
};

CashflowInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onZinsChange: PropTypes.func.isRequired,
    addCashFlowEntry: PropTypes.func.isRequired,
    removeCashflowEntry: PropTypes.func.isRequired,
    zins: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired
};