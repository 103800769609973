import React from 'react';

import ReactChartkick from 'react-chartkick';
import Highcharts from 'highcharts';

ReactChartkick.addAdapter(Highcharts);

import '@fortawesome/fontawesome-free/css/all.min.css';
import { CashflowBarChart } from './CashflowBarChart';
import { Kapitalwertfunktion } from './Kapitalwertfunktion';
import { Kennzahlen } from './Kennzahlen';
import { CashflowInput } from './CashflowInput';

export class Investition extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            investition: [-100, 50, 40, 30, 20, 10],
            zins: 0.1
        };

        this.onChange = this.onChange.bind(this);
        this.addCashFlowEntry = this.addCashFlowEntry.bind(this);
        this.removeCashflowEntry = this.removeCashflowEntry.bind(this);
        this.onZinsChange = this.onZinsChange.bind(this);
    }

    onChange(e, i) {
        if (Number.isNaN(+e.target.value))
            return;

        let copy = [...this.state.investition];
        copy[i] = +e.target.value;
        this.setState({
            investition: copy
        });
    }

    addCashFlowEntry() {
        if (this.state.investition.length >= 10)
            return;

        let copy = [...this.state.investition];
        copy.push(0);
        this.setState({
            investition: copy
        });
    }

    removeCashflowEntry() {
        if (this.state.investition.length <= 1)
            return;

        let copy = [...this.state.investition];
        copy.pop();
        this.setState({
            investition: copy
        });
    }

    onZinsChange(e) {
        if (Number.isNaN(+e.target.value))
            return;
        this.setState({
            zins: +e.target.value
        });
    }

    render() {
        return (
            <div className="m-5">
                <div className="m-3">
                    <CashflowInput zins={this.state.zins} data={this.state.investition} onChange={this.onChange} onZinsChange={this.onZinsChange} addCashFlowEntry={this.addCashFlowEntry} removeCashflowEntry={this.removeCashflowEntry}/>
                </div>
                <div className="m-3">
                    <div className="mb-10">
                        <CashflowBarChart data={this.state.investition}/>
                    </div>
                    <div>
                        <Kapitalwertfunktion data={this.state.investition}/>
                    </div>
                </div>
                <div className="m-3">
                    <div className="text-center">
                        <Kennzahlen data={this.state.investition} zins={this.state.zins}/>
                    </div>
                </div>
            </div>
        );
    }
}