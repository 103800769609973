import React from 'react';
import PropTypes from 'prop-types';

import MathJax from 'react-mathjax';
import { kapitalwert, endwert, annuitaet } from '../lib/finmath';

export const Finanzplan = (props) => {
    //let entnahme = [0, 0, 0, 0, 0, 33.69]; // endwert entnahme
    //let entnahme = [20,92, 0, 0, 0, 0, 0]; // kw entnahme
    //let entnahme = [0, 5.52, 5.52, 5.52, 5.52, 5.52]; // annuität
    let entnahme = []; // nix
    if (props.entnahme === 'kw') {
        let d = [-kapitalwert(props.zins, props.data)];
        for (let i = 1; i < props.data.length; i++)
            d.push(0);
        entnahme = d;
    } else if (props.entnahme === 'ew') {
        let d = [];
        for (let i = 1; i < props.data.length; i++)
            d.push(0);
        d.push(-endwert(props.zins, props.data));
        entnahme = d;
    } else if (props.entnahme === 'annu') {
        let d = [0];
        let a = annuitaet(props.zins, props.data);
        for (let i = 1; i < props.data.length; i++)
            d.push(-a);
        entnahme = d;
    } else
        for (let i = 0; i < props.data.length; i++)
            entnahme.push(0);

    let kapitalbindung = [(-props.data[0]) - entnahme[0]];
    let zinsen = [0];
    let kapitanfreisetzung = [0];
    for (let i = 1; i < props.data.length; i++) {
        let kf = -(props.data[i] + entnahme[i] - (props.zins * kapitalbindung[i-1]));
        let z = -props.zins * kapitalbindung[i-1];
        zinsen.push(z);
        kapitanfreisetzung.push(kf);
        kapitalbindung.push(kapitalbindung[i-1] + kf);
    }

    return (
        <div className="inline-block">
            <table>
                <thead>
                    <tr>
                        <th className="py-4 px-6 bg-grey-lighter font-sans font-medium uppercase text-sm text-grey border-b border-grey-light">
                            Zeitpunkt <MathJax.Node inline formula={'t'}/>
                        </th>
                        {props.data.map((i, k) => {
                            return (<th key={k} className="py-4 px-6 bg-grey-lighter font-sans font-medium uppercase text-sm text-grey border-b border-grey-light">{k}</th>);
                        })}
                    </tr>
                </thead>
                <tbody className="text-left">
                    <tr className="hover:bg-teal-light">
                        <td className="py-4 px-6 border-b border-grey-light">
                            EZÜ <MathJax.Node inline formula={'e_t'}/>
                        </td>
                        {props.data.map((i, k) => {
                            return (<td key={k} className="py-4 px-6 border-b border-grey-light text-right">{i.toFixed(2)}</td>);
                        })}
                    </tr>
                    <tr className="hover:bg-teal-light">
                        <td className="py-4 px-6 border-b border-grey-light">
                            Entnahme <MathJax.Node inline formula={'-c_t'}/>
                        </td>
                        {entnahme.map((i, k) => {
                            return (<td key={k} className="py-4 px-6 border-b border-grey-light text-right">{i.toFixed(2)}</td>);
                        })}
                    </tr>
                    <tr className="hover:bg-teal-light">
                        <td className="py-4 px-6 border-b border-grey-light">
                            Zinsen <MathJax.Node inline formula={'-i\\cdot\\text{KB}_{t-1}'}/>
                        </td>
                        {zinsen.map((i, k) => {
                            return (<td key={k} className="py-4 px-6 border-b border-grey-light text-right">{i.toFixed(2)}</td>);
                        })}
                    </tr>
                    <tr className="hover:bg-teal-light">
                        <td className="py-4 px-6 border-b border-grey-light">
                            Kapitalfreisetzung <MathJax.Node inline formula={'-(e_t-c_t-i\\cdot\\text{KB}_{t-1})'}/>
                        </td>
                        {kapitanfreisetzung.map((i, k) => {
                            return (<td key={k} className="py-4 px-6 border-b border-grey-light text-right">{i.toFixed(2)}</td>);
                        })}
                    </tr>
                    <tr className="hover:bg-teal-light">
                        <td className="py-4 px-6 border-b border-grey-light">
                            Kapitalbindung <MathJax.Node inline formula={'\\text{KB}_{t}'}/>
                        </td>
                        {kapitalbindung.map((i, k) => {
                            return (<td key={k} className="py-4 px-6 border-b border-grey-light text-right">{i.toFixed(2)}</td>);
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

Finanzplan.propTypes = {
    data: PropTypes.array.isRequired,
    zins: PropTypes.number.isRequired
};