export function kapitalwert(i, cf) {
    let kw = 0;
    for (let t = 0; t < cf.length; t++) {
        kw += cf[t] / Math.pow(1+i, t);
    }
    return kw;
}

export function endwert(i, cf) {
    return kapitalwert(i, cf) * Math.pow(1+i, cf.length - 1);
}

export function annuitaet(i, cf) {
    return kapitalwert(i, cf) * i / (1 - Math.pow(1+i, -cf.length + 1));
}

export function ewigeRente(i, cf) {
    return kapitalwert(i, cf) * i;
}

export function kapitalwertrate(i, cf) {
    return kapitalwert(i, cf) / -cf[0];
}