import React from 'react';
import MathJax from 'react-mathjax';

export function Zahlungsreihe(props) {
    const form = '(e_0, e_1, e_2, ..., e_T)';
    return (<Formula {...props} formula={form}/>);
}

export function Kapitalwert(props) {
    const form = '\\textit{KW}=\\sum_{t=0}^T\\frac{e_t}{(1+i)^{t}}';
    return (<Formula {...props} formula={form}/>);
}

export function Endwert(props) {
    const form = 'B_T=\\sum_{t=1}^T\\frac{e_t}{(1+i)^{T-t}}=K(1+i)^T';
    return (<Formula {...props} formula={form}/>);
}

export function Annuitaet(props) {
    const form = 'g=\\frac{i}{1-(1+i)^{-T}}K';
    return (<Formula {...props} formula={form}/>);
}
export function EwigeRente(props) {
    const form = 'g_\\infty=iK';
    return (<Formula {...props} formula={form}/>);
}

export function Kapitalwertrate(props) {
    const form = 'K_R=\\frac{K}{-e_0}';
    return (<Formula {...props} formula={form}/>);
}
export function Zins(props) {
    const form = 'i';
    return (<Formula {...props} formula={form}/>);
}
export function InternerZins(props) {
    const form = 'i^*\\text{, sodass }K(i^*)=0\\text{ gilt.}';
    return (<Formula {...props} formula={form}/>);
}

function Formula(props) {
    return (
        <div className={props.inline ? 'inline-block' : 'block'}>
            <MathJax.Node formula={props.formula}/>
        </div>
    );
}