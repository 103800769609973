import React from 'react';
import PropTypes from 'prop-types';

export const Box = (props) => {
    return (
        <div className={'border shadow border-teal m-1 py-2 px-3 rounded lg:m-0 lg:rounded-none lg:border-r-0 lg:shadow-none' + (props.first ? ' lg:rounded-l' : '') + (props.last ? ' lg:border-r lg:rounded-r' : '')}>
            <span className="font-bold">
                {props.name}
            </span>
            <br/>
            <span>
                {props.item}
            </span>
        </div>
    );
};


Box.propTypes = {
    first: PropTypes.boolean,
    last: PropTypes.boolean,
    name: PropTypes.string,
    item: PropTypes.string,
};