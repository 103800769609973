import React from 'react';
import PropTypes from 'prop-types';

import { ColumnChart } from 'react-chartkick';

export const CashflowBarChart = (props) => {
    return (
        <ColumnChart
            legend={false}
            data={[
                {
                    name:'Cashflow',
                    data : props.data.map((i, k) => {
                        return [k, i];
                    })
                }
            ]}
            max={Math.max(...props.data) * 1.5}
            min={Math.min(...props.data) * 1.5}
            colors={props.color ? [props.color] : ['#4DC0B5']}
        />
    );
};

CashflowBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    color: PropTypes.string
};