import React from 'react';
import MathJax from 'react-mathjax';
import { Hor } from './Hor';


export class Zinstrafo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onJahresZinsChange = this.onJahresZinsChange.bind(this);
        this.onQuartalsZinsChange = this.onQuartalsZinsChange.bind(this);
        this.onMonatsZinsChange = this.onMonatsZinsChange.bind(this);
        this.onKontZinsChange = this.onKontZinsChange.bind(this);
    }

    onJahresZinsChange(e) {
        let i = +e.target.value;
        this.setState({
            jahreszins: i,
            monatszins: Math.pow(1+i, 1.0/12) - 1,
            quartalszins: Math.pow(1+i, 1.0/4) - 1,
            kontzins: Math.log(1+i)
        });
        document.getElementById('quartalszins').value = '';
        document.getElementById('monatszins').value = '';
        document.getElementById('kontzins').value = '';
    }

    onQuartalsZinsChange(e) {
        let i = +e.target.value;
        this.setState({
            jahreszins: Math.pow(1+i, 4) - 1,
            monatszins: Math.pow(1+i, 1.0/3) - 1,
            quartalszins: i,
            kontzins: Math.log(Math.pow(1+i, 4))
        });
        document.getElementById('jahreszins').value = '';
        document.getElementById('monatszins').value = '';
        document.getElementById('kontzins').value = '';
    }

    onMonatsZinsChange(e) {
        let i = +e.target.value;
        this.setState({
            jahreszins: Math.pow(1+i, 12) - 1,
            monatszins: i,
            quartalszins: Math.pow(1+i, 3) - 1,
            kontzins: Math.log(Math.pow(1+i, 12))
        });
        document.getElementById('jahreszins').value = '';
        document.getElementById('quartalszins').value = '';
        document.getElementById('kontzins').value = '';
    }

    onKontZinsChange(e) {
        let i = +e.target.value;
        this.setState({
            jahreszins: Math.exp(i)-1,
            monatszins: Math.pow(Math.exp(i), 1.0/12) - 1,
            quartalszins: Math.pow(Math.exp(i), 1.0/4) - 1,
            kontzins: i
        });
        document.getElementById('jahreszins').value = '';
        document.getElementById('quartalszins').value = '';
        document.getElementById('monatszins').value = '';
    }

    render() {
        return (
            <div className="container mx-auto sm:shadow-lg sm:border sm:rounded text-center my-4">
                <div className="m-3">
                    <div className="inline-block">
                        <input
                            type="text"
                            placeholder={this.state.jahreszins || 'Jahreszins'}
                            onChange={this.onJahresZinsChange}
                            id="jahreszins"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:rounded-l xl:mx-0 xl:border-r-0"
                        />
                        <input
                            type="text"
                            placeholder={this.state.quartalszins || 'Quartalszins'}
                            onChange={this.onQuartalsZinsChange}
                            id="quartalszins"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:mx-0 xl:border-r-0"
                        />
                        <input
                            type="text"
                            placeholder={this.state.monatszins || 'Monatszins'}
                            onChange={this.onMonatsZinsChange}
                            id="monatszins"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:mx-0 xl:border-r-0"
                        />
                        <input
                            type="text"
                            placeholder={this.state.kontzins || 'kont. Zins'}
                            id="kontzins"
                            onChange={this.onKontZinsChange}
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:rounded-r xl:mx-0"
                        />
                    </div>
                </div>
                <div className="m-3 text-left">
                    <Hor/>
                    Sei <MathJax.Node inline formula={'i'}/> der Jahreszins.
                    Der Quartalszins ist <MathJax.Node inline formula={'r_q=\\sqrt[4]{1+i}-1'}/>.
                    <br/>
                    Der Monatszins ist <MathJax.Node inline formula={'r_m=\\sqrt[12]{1+i}-1'}/>.
                    <br/>
                    Der kontinuierliche Zins ist gegeben durch <MathJax.Node inline formula={'r_{c}=\\ln(1+i)'}/>.
                </div>
            </div>
        );
    }
}