import React from 'react';

export const Impressum = () => {
    return (
        <div className="container mx-auto text-center m-5 p-5 shadow-md rounded border border-teal text-teal-dark bg-grey-lightest">
            <span className="font-bold">Made with <i className="fas fa-heart"></i> by Tim David Brachwitz</span>
            <br/>
            <span className="font-bold">{'brachwitz' + '@' + 'campus' + '.tu' + '-' + 'berlin' + '.de'}</span>
            <br/>
            <span className="leading-normal">Die Webseite wurde leider noch nicht für mobile Geräte optimiert</span>
        </div>
    );
};