import React from 'react';
import { Kapitalwert, Zahlungsreihe } from './Formeln';
import { Link } from 'react-router-dom';

import MathJax from 'react-mathjax';
import { Hor } from './Hor';

export class Home extends React.Component {
    render() {
        return (
            <div>
            <div className="container mx-auto p-5 text-grey-darkest leading-tight sm:shadow-lg my-4 rounded">
                <h1>Investition und Finanzierung - Grundlagen (WIP)</h1>
                Diese Webseite ist für Studierende gedacht, die die Veranstaltung &quot;Investition und Finanzierung&quot; nach dem Skript von Prof. Dr. Hans Hirth belegen.
                <br/>
                Eine tolle weitere Quelle im Web ist: <a className="hover:text-teal-darkest no-underline text-teal" href="http://hans-markus.de/finance/7/grundlagen/">hans-markus.de</a>
                <Hor/>
                <h2>Diskontierung</h2>
                Das erste, was ich in I&amp;F gelernt habe ist, dass Geld zu verschiedenen Zeitpunkten verschieden viel wert ist!
                <br/>
                Falls du die hypothetische Wahl hast, heute 100€ oder in einem Jahr 110€ zu erhalten, darfst du nicht einfach die 110€ wählen, weil es schlichtweg 10€ mehr sind.
                Du musst dich fragen:
                Können mir die 100€ innerhalb von einem Jahr mehr Geld erwirtschaften als die 10€, die ich mehr bekommen würde?
                <br/>
                In I&amp;F gehen wir davon aus, dass es immer eine Möglichkeit gibt Geld zu einem festen Zinssatz sicher anzulegen, z.B. auf einem Sparbuch <i className="fas fa-piggy-bank"></i> bei der Bank deines Vertrauens.
                <br/>
                Um Zahlungen zu verschiedenen Zeitpunkten vergleichbar zu machen, nutzen wir den Bar- oder Endwert.
                Wir bringen damit zwei Zahlungen auf den gleichen Zeitpunkt.
                <br/>
                Angenommen der Zins der sicheren Anlagemöglichkeit beträgt 3%.
                Dann könnte ich die 100€ nehmen und sie für 3% ein Jahr lang anlegen.
                Nach einem Jahr, erhalte ich 3€ Zinsen und besitze insgesamt 103€.
                Da wäre es sinnvoller gewesen gleich die 110€ zu nehmen.
                Sollte der Marktzins aber 15% sein, lohnt es sich die 100€ direkt zu nehmen und 100€ anzulegen.
                Diese 100€ erwirtschaften 15€, in Summe also 115€, mehr als die 110€.
                Das was wir gerade berechnen, nennt sich Endwert.
                <h3>Endwert <MathJax.Node formula={'B_T'} inline/></h3>
                <MathJax.Node formula={'B_1=100\\cdot(1+0.03)^1=103'} inline/> bei einem Zins von 3% und einer Periode.
                <br/>
                <MathJax.Node formula={'B_1=100\\cdot(1+0.15)^1=115'} inline/> bei einem Zins von 15% und einer Periode.
                <br/>
                Allgemein: <MathJax.Node formula={'B_T=B_t\\cdot(1+i)^T'} inline/>.

                <h3>Barwert <MathJax.Node formula={'B_0'} inline/></h3>
                Umgekehrt funktioniert der Barwert. Wir teilen anstatt zu multiplizieren. Die Zukunftswert wird in die Gegenwart &quot;transformiert&quot;.
                Der Barwert der 110€ in <MathJax.Node formula={'t_1'} inline/> ist bei einem Marktzins von 3% <MathJax.Node formula={'\\frac{110}{(1+0.03)^1}=106.79 > 100'} inline/>, was bedeutet, dass die 110€ später mehr Wert sind als die 100€ jetzt.
                Jedoch bei einem Marktzins von 15% gilt <MathJax.Node formula={'\\frac{110}{(1+0.15)^1}=95.65 < 100'} inline/>.
                In diesem Szenario sind die 100€ heute mehr wert.
                <Hor/>
                <h2>Unterjährige Verzinsung</h2>
                Im Allgemeinen werden Zinssätze pro Jahr angegeben.
                Stell dir vor jemand bietet dir einen Kredit über A€ mit 5% Zinsen p.a. an, der 6 Monate Laufzeit hat.
                Wieviele Zinsen musst du zahlen?

                Sei <MathJax.Node formula={'r'} inline/> der Monatszins und <MathJax.Node formula={'i=5\\%'} inline/> der Jahreszins.
                Da der Monatszins 12 mal wirken muss, um den gleichen Effekt zu haben wie der Jahreszins, gilt <MathJax.Node formula={'(1+r)^{12}=1+i'}/>
                Mit dieser Formel können wir unseren Monatszins berechnen <MathJax.Node formula={'r=\\sqrt[12]{1+i}-1=\\sqrt[12]{1.05}-1=0.00407412378365\\approx0.4\\%'}/>
                Da der Kredit 6 Monate läuft wirkt der Monatszins 6 mal.
                &nbsp;
                <MathJax.Node formula={'A(1+r)^6=A{\\sqrt[12]{1+i}}^{6}=A{\\sqrt[12]{1.05}}^{6} \\approx 1.024A\\text{.}'} inline/>&nbsp;
                
                Was aber, wenn wir den Zeitraum einer Verzinsung noch kleiner machen? Ein Jahr? Ein Monat? Eine Woche? Ein Tag? Eine Stunde? Eine Sekunde?
                Es stellt sich heraus, dass zu jedem Jahreszins ein kontinuierlicher Zins existiert.

                <MathJax.Node formula={'r=\\ln(1+i)'}/>


                <br/>
                Hier geht's zum <Link className="hover:text-teal-darkest no-underline text-teal" to="/zinsen">Zinsrechner</Link>.

                <Hor/>
                <h2>Statische Investitionsverfahren</h2>
                GVR, KVR, RVR, Amortisationsrechnung
                <Hor/>
                <h2>Der Kapitalwert</h2>
                Der Kapitalwert ist ein Maß, das die Güte im Sinne der Lukrativität einer Zahlungsreihe quantifiziert.
                Er erlaubt es verschiedene Investitionen zu vergleichen.
                <br/>
                Eine Investition entspricht einer Zahlungsreihe <Zahlungsreihe inline/>. Der Kapitalwert errechnet sich aus der Summe der Barwerte:
                <Kapitalwert/>
                Ein positiver Kapitalwert spricht für eine sinnvolle Investition.
                Ein negativer KW für eine schlechte Investition.

                <hr/>
                Die Investition <MathJax.Node formula={'(-100, 110)'} inline/> kostet uns in <MathJax.Node formula={'t_0'} inline/> 100€, erwirtschaftet uns aber 110€ in <MathJax.Node formula={'t_1'} inline/>.
                Bei einem Marktzins von 5% beläuft sich der Kaptialwert auf 4.7619€.
                <div className="block sm:hidden">
                    <MathJax.Node formula={'\\textit{KW} = -100 + \\frac{110}{(1+0.05)^{1}}'}/>
                    <MathJax.Node formula={'=-100 + \\frac{110}{1.05}'}/>
                    <MathJax.Node formula={'=4.7619047619'}/>
                </div>
                <div className="sm:block hidden">
                    <MathJax.Node formula={'\\textit{KW} = -100 + \\frac{110}{(1+0.05)^{1}}=-100 + \\frac{110}{1.05}=4.7619047619'}/>
                </div>
                Da sich der Kapitalwert auf den Zeitpunkt <MathJax.Node formula={'t_0'} inline/> bezieht, können wir in <MathJax.Node formula={'t_0'} inline/> einen Kredit in Höhe des Kapitalwertes 4.7619€ aufnehmen.
                Die Zinskosten wird von den Rückflüssen der Investition getragen.
                Also nochmal: Der Kapitalwert ist ein Betrag, den wir heute auf Kredit verprassen könnten und die Investition tilgt den Zinsaufwand.
                <Hor/>
                <h2>Die Annuität</h2>
                Angenommen du gewinnst heute 1.000.000€ im Lotto und entscheidest dich daher sofort in Rente zu gehen.
                Außerdem hast die Möglichkeit das Geld für einen Zins von 3% anzulegen.
                Du möchtest dir regelmäßig von deinem Geld etwas abzweigen.
                Du rechnest damit, dass du in 40 Jahren stirbst.
                Wie hoch muss der Betrag <MathJax.Node formula={'g'} inline/> sein, den du dir jedes Jahr auszahlen lässt, sodass am Tag deines Todes das Kapital vollkommen verzehrt wurde.
                Dieser Betrag, den wir regelmäßig auszahlen nennen wir Annuität.

                Wir leiten eine Formel für die Annuität her.

                Wir wissen, dass der Barwert der Entnahme dem heutigen Kapital gleichen muss.
                <MathJax.Node formula={'\\sum_{t=1}^Tg\\cdot(1+i)^{-t}=K'}/>
                <MathJax.Node formula={'g\\sum_{t=1}^T(1+i)^{-t}=K'}/>
                <MathJax.Node formula={'g=\\frac{K}{\\sum_{t=1}^T(1+i)^{-t}}'}/>
                Um die Summenformel im Nenner loszuwerden nutzen wir die folgende Identität
                <MathJax.Node formula={'\\sum_{t=1}^T(1+i)^{-t}=\\frac{1-(1+i)^{-T}}{i}'}/>

                Daher gilt: <MathJax.Node formula={'g=\\underbrace{\\frac{i}{1-(1+i)^{-T}}}_{\\text{Rentenwiedergewinnungsfaktor}}\\cdot K'}/>

                Nun zurück zu unserem Beispiel. <MathJax.Node formula={'T=40'} inline/>, <MathJax.Node formula={'K=1000000'} inline/>, <MathJax.Node formula={'i=0.03'} inline/>.

                <MathJax.Node formula={'g=\\frac{0.03}{1-(1.03)^{-40}}\\cdot 1000000=43262.3778905'}/>

                Das bedeutet, dass wir uns für 40 Jahre lang jedes Jahr 43262.37€ auszahlen lassen können.

                Im Spezialfall <MathJax.Node formula={'T\\to\\infty'} inline/> nennt sich die Annuität die ewige Rente <MathJax.Node formula={'g_\\infty=i\\cdot K\\text{,}'}/> denn

                <MathJax.Node formula={'g=\\frac{i}{1-\\underbrace{(1+i)^{-T}}_{0\\text{, für }T\\to\\infty}}\\cdot K'}/>
                
                Das ist der Betrag, den das Kapital konstant jedes Jahr erwirtschaftet. Diese Gewinne können wir jedes Jahr abschöpfen ohne, dass das Kapital verzehrt wird.

                <Hor/>
                <h2>Der interne Zinssatz</h2>
                Oben haben wir den Kapitalwert kennengelernt.
                Nun begreifen wir den Kapitalwert als Funktion abhängig von i.
                <MathJax.Node formula={'K(i)=\\sum_{t=0}^T\\frac{e_t}{(1+i)^{t}}'}/>
                <MathJax.Node formula={'K(i)'} inline/> ist ein Polynom vom Grad <MathJax.Node formula={'T'} inline/>.
                Der interne Zins ist der Zins <MathJax.Node formula={'i^*'} inline/> an dem <MathJax.Node formula={'K(i^*)=0'} inline/>.
                <Hor/>
                <h2>Kapitalwertrate</h2>
                <MathJax.Node formula={'\\frac{K}{-e_0}'}/>
                <Hor/>
                <h2>Enbeziehung von Steuern</h2>
                <Hor/>
                <h2>Einbeziehung von Risiko</h2>
                Sei <MathJax.Node formula={'\\operatorname{E}(e_t)'} inline/> der Erwartungswert der Einzahlungen und <MathJax.Node formula={'{\\text{RP}}_t'} inline/> die Risikoprämie.
                <MathJax.Node formula={'K=\\sum_{t=0}^T\\frac{\\operatorname{E}(e_t)-{\\text{RP}}_t}{(1+i)^{t}}'}/>
                <MathJax.Node formula={'K=\\sum_{t=0}^T\\frac{\\operatorname{E}(e_t)}{(1+i+{\\text{rp}_t})^{t}}'}/>
                <Hor/>
                <h2>Der Leverage-Effekt</h2>
                Das Gesamtkapital ist die Summe von Fremd- und Eigenkapital: <MathJax.Node formula={'\\text{GK}=\\text{EK}+\\text{FK}'} inline/>.
                Die Rendite, die das Gesamtkapital erbringt ist <MathJax.Node formula={'r_G\\cdot\\text{GK}'} inline/>.
                Einsatz des Eigenkapitals bringt:
                <MathJax.Node formula={'r_E\\cdot\\text{EK}'} inline/>.
                Fremdkapitalkosten sind <MathJax.Node formula={'r_F\\cdot\\text{FK}'} inline/>.

                Daher gilt
                <MathJax.Node formula={'\\underbrace{r_E\\cdot\\text{EK}}_{\\text{Nettogewinn}}=\\underbrace{r_G\\cdot\\text{GK}}_{\\text{Bruttogewinn}}-\\underbrace{r_F\\cdot\\text{FK}}_{\\text{FK-Kosten}}'}/>.
                Umformen nach Eigenkapitalrendite ergibt.
                <MathJax.Node formula={'r_E=\\frac{r_G(\\text{EK}+\\text{FK})-r_F\\text{FK}}{\\text{EK}}'}/>
                <MathJax.Node formula={'r_E=r_G(1+\\frac{\\text{FK}}{\\text{EK}})-r_F\\frac{\\text{FK}}{\\text{EK}}'}/>
                Wir definieren nun den Verschuldungsgrad als <MathJax.Node formula={'\\text{VG}=\\frac{\\text{FK}}{\\text{EK}}'} inline/>.
                <MathJax.Node formula={'r_E=r_G(1+\\text{VG})-r_F\\text{VG}'}/>
                <MathJax.Node formula={'r_E=r_G+r_G\\text{VG}-r_F\\text{VG}'}/>
                <MathJax.Node formula={'r_E=r_G+(r_G-r_F)\\text{VG}'}/>

                Jetzt kann man sehen, dass die Eigenkapitalrendite linear vom Verschuldungsgrad abhängt.
                Wir nehmen an, dass nur der Fremdkapitalzins <MathJax.Node formula={'r_F'} inline/> sicher ist.
                Die Renditen <MathJax.Node formula={'r_G'} inline/> und daher auch <MathJax.Node formula={'r_E'} inline/> sind unsicher.
                Daher nutzen wir den Erwartungswert, der Renditen.
                
                Damit stellt man die Leverage-Gleichung auf:
                <MathJax.Node formula={'\\operatorname{E}(r_E)=\\operatorname{E}(r_G) + (\\operatorname{E}(r_G)-r_F)\\cdot\\text{VG}'}/>
                Das Leverage-Risiko ist:
                <MathJax.Node formula={'\\operatorname{Var}(r_E)=(1+\\text{VG})^2\\cdot\\operatorname{Var}(r_G)'}/>
                Oder auch als Standardabweichung <MathJax.Node formula={'\\sigma_{r_E}=\\sqrt{\\operatorname{Var}(r_E)}'} inline/> mit <MathJax.Node formula={'\\sigma_{r_G}=\\sqrt{\\operatorname{Var}(r_G)}'} inline/>.
                <MathJax.Node formula={'\\sigma_{r_E}=(1+\\text{VG})\\cdot\\sigma_{r_G}=\\underbrace{\\sigma_{r_G}}_{\\text{Geschäftsrisiko}}+\\underbrace{\\text{VG}\\cdot\\sigma_{r_G}}_{\\text{Kapitalstrukturrisiko}}'}/>.

                <Hor/>
                <h2>Modigliani-Miller-These</h2>
            </div>
            <div className="container mx-auto p-5 text-grey-darkest leading-tight sm:shadow-lg my-4 rounded">
                <h1>Finanzierung und Investition 1</h1>
                Ab 2019
                <h2>CAPM</h2>
            </div>
            <div className="container mx-auto p-5 text-grey-darkest leading-tight sm:shadow-lg my-4 rounded">
                <h1>Finanzierung und Investition 2</h1>
            </div>
            <div className="container mx-auto p-5 text-grey-darkest leading-tight sm:shadow-lg my-4 rounded">
                <h1>Finanzierung und Investition 3</h1>
            </div>
            </div>
        );
    }
}