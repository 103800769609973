import React from 'react';

import logo from '../TU-Berlin-Logo.svg';
import { Link } from 'react-router-dom';


export class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return (
            <nav className="flex items-center justify-between flex-wrap bg-teal p-3 text-white">
                <div className="flex items-center flex-no-shrink mr-6">
                    <img className="mr-2" src={logo} style={{
                        width: '40px',
                        color: 'white'
                    }}/>
                    <span className="font-semibold text-xl tracking-tight">I &amp; F</span>
                </div>
                <div className="block">
                    <button className="lg:hidden flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white" onClick={this.toggle.bind(this)}>
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                    </button>
                </div>
                <div className={'w-full block lg:flex lg:items-center lg:w-auto lg:flex-grow' + (!this.state.open ? ' hidden' : '')}>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4 " to="/">Home</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/cashflow">Cashflowanalyse</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/zinsen">Zinsen</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/plan">Finanzplan</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/annu">Annuitätenrechner</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/indifferenzkurven">Indifferenzkurven</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/fisher">Fisher</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/dean">Dean</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/optionen">Optionen</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/capm">CAPM</Link>
                    </div>
                    <div className="text-sm my-2">
                        <Link className="no-underline inline-block text-teal-lighter hover:text-white mr-4" to="/impress"><i className="far fa-money-bill-alt"> About</i></Link>
                    </div>
                </div>
                
            </nav>
        );
    }
}