import React from 'react';
import PropTypes from 'prop-types';

import { LineChart } from 'react-chartkick';
import { kapitalwert } from '../lib/finmath';

export const Kapitalwertfunktion = (props) => {
    let kwPoints = [];
    let zero = [];
    for (let i = -0.5; i < 0.5; i += 0.001) {
        let kw = +kapitalwert(i, props.data).toFixed(4);
        kwPoints.push([+i.toFixed(6), kw]);
        zero.push([+i.toFixed(6), 0]);
    }
    let data = [
        {
            name: 'Nullfunktion',
            data: zero,
            color: '#20504F'
        },
        {
            name: 'Kapitalwertfunktion',
            data: kwPoints,
            color: props.color ? props.color : '#4DC0B5'
        }
    ];
    return (<LineChart legend={true} data={data}/>);
};

LineChart.propTypes = {
    data: PropTypes.array.isRequired,
    color: PropTypes.string
};