import React from 'react';
import MathJax from 'react-mathjax';
import { Hor } from './Hor';
import { Annuitaet } from './Formeln'

export class Annu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onJahresZinsChange = this.onJahresZinsChange.bind(this);
        this.onLaufzeitChange = this.onLaufzeitChange.bind(this);
        this.onKapitalChange = this.onKapitalChange.bind(this);
    }

    onJahresZinsChange(e) {
        this.setState({
            jahreszins: +e.target.value
        })
    }

    onLaufzeitChange(e) {
        this.setState({
            laufzeit: +e.target.value
        })
    }

    onKapitalChange(e) {
        this.setState({
            kapital: +e.target.value
        })
    }

    render() {
        return (
            <div className="container mx-auto sm:shadow-lg sm:border sm:rounded text-center my-4">
                <Annuitaet/>
                <div className="m-3">
                    <div className="inline-block">
                        <input
                            type="text"
                            placeholder={this.state.jahreszins || 'Jahreszins'}
                            onChange={this.onJahresZinsChange}
                            id="jahreszins"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:rounded-l xl:mx-0 xl:border-r-0"
                        />
                        <input
                            type="text"
                            placeholder={this.state.laufzeit || 'Laufzeit'}
                            onChange={this.onLaufzeitChange}
                            id="laufzeit"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:mx-0 xl:border-r-0"
                        />
                        <input
                            type="text"
                            placeholder={this.state.kapital || 'Kapital'}
                            onChange={this.onKapitalChange}
                            id="kapital"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:mx-0 xl:border-r-0"
                        />
                        <input
                            disabled
                            type="text"
                            placeholder={this.state.kapital*this.state.jahreszins/(1-Math.pow(1+this.state.jahreszins, -this.state.laufzeit)) || 'Annuität'}
                            id="annu"
                            className="border py-2 px-3 text-grey-darker m-1 rounded xl:rounded-none xl:rounded-r xl:mx-0"
                        />
                    </div>
                </div>
            </div>
        );
    }
}