import React from 'react';
import { Finanzplan } from './Finanzplan';
import { Kennzahlen } from './Kennzahlen';
import { CashflowInput } from './CashflowInput';

export class Plan extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            investition: [-100, 50, 40, 30, 20, 10],
            zins: 0.1,
            planType: ''
        };

        this.onChange = this.onChange.bind(this);
        this.addCashFlowEntry = this.addCashFlowEntry.bind(this);
        this.removeCashflowEntry = this.removeCashflowEntry.bind(this);
        this.onZinsChange = this.onZinsChange.bind(this);
    }

    onChange(e, i) {
        if (Number.isNaN(+e.target.value))
            return;

        let copy = [...this.state.investition];
        copy[i] = +e.target.value;
        this.setState({
            investition: copy
        });
    }

    addCashFlowEntry() {
        if (this.state.investition.length >= 10)
            return;

        let copy = [...this.state.investition];
        copy.push(0);
        this.setState({
            investition: copy
        });
    }

    removeCashflowEntry() {
        if (this.state.investition.length <= 1)
            return;

        let copy = [...this.state.investition];
        copy.pop();
        this.setState({
            investition: copy
        });
    }

    onZinsChange(e) {
        if (Number.isNaN(+e.target.value))
            return;
        this.setState({
            zins: +e.target.value
        });
    }

    render() {
        return (
            <div className="container mx-auto">
                <div className="m-3">
                    <CashflowInput zins={this.state.zins} data={this.state.investition} onChange={this.onChange} onZinsChange={this.onZinsChange} addCashFlowEntry={this.addCashFlowEntry} removeCashflowEntry={this.removeCashflowEntry}/>
                    <div className="mt-1 text-center block sm:inline-block">
                        <div className="py-2 px-3 bg-grey-lighter border block border-b-0 w-full rounded-t sm:inline-block sm:w-auto sm:border sm:border-r-0 sm:rounded-r-none sm:rounded-l">Entnahme</div>
                        <button className={'py-2 px-3 border block w-full border-b-0 sm:inline-block sm:w-auto sm:border-r-0 sm:border-b' + (this.state.planType == '' ? ' bg-teal-light' : '')} onClick={(() => { this.setState({planType: ''});}).bind(this)}>Keine</button>
                        <button className={'py-2 px-3 border block w-full border-b-0 sm:inline-block sm:w-auto sm:border-r-0 sm:border-b' + (this.state.planType == 'kw' ? ' bg-teal-light' : '')} onClick={(() => { this.setState({planType: 'kw'});}).bind(this)}>Kapitalwert</button>
                        <button className={'py-2 px-3 border block w-full border-b-0 sm:inline-block sm:w-auto sm:border-r-0 sm:border-b' + (this.state.planType == 'ew' ? ' bg-teal-light' : '')} onClick={(() => { this.setState({planType: 'ew'});}).bind(this)}>Endwert</button>
                        <button className={'py-2 px-3 border block w-full rounded-b sm:inline-block sm:w-auto sm:border-b sm:rounded-l-none sm:rounded-r' + (this.state.planType == 'annu' ? ' bg-teal-light' : '')} onClick={(() => { this.setState({planType: 'annu'});}).bind(this)}>Annuitäten</button>
                    </div>
                </div>
                <div className="m-3 text-grey-darkest p-3">
                    <p className="font-bold">{(() => {
                        if (this.state.planType == 'kw')
                            return 'Finanzplan bei Entnahme des Kapitalwerts';
                        if (this.state.planType == 'ew')
                            return 'Finanzplan bei Entnahme des Endwerts';
                        if (this.state.planType == 'annu')
                            return 'Finanzplan bei Entnahme der Annuitäten';
                        return 'Finanzplan ohne Entnahme';
                    })()}</p>
                    <div className="text-center m-3 overflow-x-auto">
                        <Finanzplan data={this.state.investition} zins={this.state.zins} entnahme={this.state.planType}/>
                    </div>
                </div>
                <div className="text-center m-3">
                    <Kennzahlen data={this.state.investition} zins={this.state.zins}/>
                </div>
            </div>
        );
    }
}