import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { kapitalwert, endwert, annuitaet, ewigeRente, kapitalwertrate } from '../lib/finmath';
import { Box } from './Box';
import SolveWithNewton from '../SolveWithNewton';
import { Kapitalwert, Endwert, Annuitaet, EwigeRente, Kapitalwertrate, Zins, InternerZins } from './Formeln';

export const Kennzahlen = (props) => {
    return (
        <div className="inline-block lg:shadow">
            <div className="inline-block" data-tip data-for="kapitalwert">
                <Box name="Kapitalwert" item={kapitalwert(props.zins, props.data).toFixed(4)} first={true}/>
            </div>
            <ReactTooltip id='kapitalwert' effect="solid">
                <Kapitalwert/>
            </ReactTooltip>
            <div className="inline-block" data-tip data-for="endwert">
                <Box name="Endwert" item={endwert(props.zins, props.data).toFixed(4)}/>
            </div>
            <ReactTooltip id='endwert' effect="solid">
                <Endwert/>
            </ReactTooltip>
            <div className="inline-block" data-tip data-for="annuitaet">
                <Box name="Annuität" item={annuitaet(props.zins, props.data).toFixed(4)}/>
            </div>
            <ReactTooltip id='annuitaet' effect="solid">
                <Annuitaet/>
            </ReactTooltip>
            <div className="inline-block" data-tip data-for="ewigerente">
                <Box name="ewige Rente" item={ewigeRente(props.zins, props.data).toFixed(4)}/>
            </div>
            <ReactTooltip id='ewigerente' effect="solid">
                <EwigeRente/>
            </ReactTooltip>
            <div className="inline-block" data-tip data-for="kapitalwertrate">
                <Box name="Kapitalwertrate" item={kapitalwertrate(props.zins, props.data).toFixed(4)}/>
            </div>
            <ReactTooltip id='kapitalwertrate' effect="solid">
                <Kapitalwertrate/>
            </ReactTooltip>
            <div className="inline-block" data-tip data-for="zins">
                <Box name="Zins" item={(props.zins*100).toFixed(2) + '%'}/>
            </div>
            <ReactTooltip id='zins' effect="solid">
                <Zins/>
            </ReactTooltip>
            <div className="inline-block" data-tip data-for="internerzins">
                <Box name="Interner Zins" item={(100*SolveWithNewton(i => kapitalwert(i, props.data), 0)).toFixed(4) + '%'} last={true}/>
            </div>
            <ReactTooltip id='internerzins' effect="solid">
                <InternerZins/>
                <span>Der interne Zins wird hier mittels der Newton-Methode berechnet.<br/>Wie in der Vorlesung behandelt, kann es sein, dass es keinen oder mehrere i* gibt.<br/>Bitte beachte dies hier.</span>
            </ReactTooltip>
        </div>
    );
};

Kennzahlen.propTypes = {
    data: PropTypes.array.isRequired,
    zins: PropTypes.number.isRequired
};